.footer-links-section {
  padding-right: 50px;
  padding-top: 50px;
}

.first {
  padding-left: 20px;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.white {
  color: white;
}

.white a {
  color: white;
  text-decoration: none;
  font-family: 'Inconsolata', sans-serif;
}

.offwhite {
  color: #ddd !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

h6.white {
  font-family: 'Inconsolata', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
  padding-top: 2rem;
  margin-bottom: 0.5rem;
}

a:hover,
.offwhite:hover {
  color: #e2712f !important;
  text-decoration: underline;
}

.footer-logo {
  padding: 50px;
  padding-right: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #fff;
  margin: 30px 30px 30px 30px;
}

.fortyeightu {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 2rem;
  font-size: 50px;
  font-family: 'Roboto Condensed';
  font-weight: 500;
  color: #1a1a1a;
  text-shadow: -1.5px -1.5px 0 white, 1.5px -1.5px 0 white, -1.5px 1.5px 0 white,
    1.5px 1.5px 0 white;
  line-height: 1.2;
  font-size: 110px;
}

.studios {
  font-family: 'Barlow Condensed';
  font-size: 50px;
  font-style: italic;
  color: white;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.button-white {
  font-family: 'Inconsolata', sans-serif;
  background-color: Transparent;
  border: 3px solid #fff;
  padding: 15px 45px 15px 45px;
  margin: 50px 0 50px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  transition: color 0.5s, background-color 0.5s;
}

.button-white:hover {
  background-color: #ff8b5c;
  border-color: #ff8b5c;
  color: white !important;
  text-decoration: none !important;
}

@media only screen and (max-width: 1200px) {
  .footer-logo {
    display: none;
  }

  .footer-links-section {
    padding-top: 0px;
  }

  .first {
    padding-left: 0px;
  }

  .last {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 750px) {
  .last {
    padding-right: 50px;
  }
}
