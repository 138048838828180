@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-links-section {
  padding-right: 50px;
  padding-top: 50px;
}

.first {
  padding-left: 20px;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.white {
  color: white;
}

.white a {
  color: white;
  text-decoration: none;
  font-family: 'Inconsolata', sans-serif;
}

.offwhite {
  color: #ddd !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

h6.white {
  font-family: 'Inconsolata', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
  padding-top: 2rem;
  margin-bottom: 0.5rem;
}

a:hover,
.offwhite:hover {
  color: #e2712f !important;
  text-decoration: underline;
}

.footer-logo {
  padding: 50px;
  padding-right: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #fff;
  margin: 30px 30px 30px 30px;
}

.fortyeightu {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 2rem;
  font-size: 50px;
  font-family: 'Roboto Condensed';
  font-weight: 500;
  color: #1a1a1a;
  text-shadow: -1.5px -1.5px 0 white, 1.5px -1.5px 0 white, -1.5px 1.5px 0 white,
    1.5px 1.5px 0 white;
  line-height: 1.2;
  font-size: 110px;
}

.studios {
  font-family: 'Barlow Condensed';
  font-size: 50px;
  font-style: italic;
  color: white;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.button-white {
  font-family: 'Inconsolata', sans-serif;
  background-color: Transparent;
  border: 3px solid #fff;
  padding: 15px 45px 15px 45px;
  margin: 50px 0 50px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  transition: color 0.5s, background-color 0.5s;
}

.button-white:hover {
  background-color: #ff8b5c;
  border-color: #ff8b5c;
  color: white !important;
  text-decoration: none !important;
}

@media only screen and (max-width: 1200px) {
  .footer-logo {
    display: none;
  }

  .footer-links-section {
    padding-top: 0px;
  }

  .first {
    padding-left: 0px;
  }

  .last {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 750px) {
  .last {
    padding-right: 50px;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: #fe7e14 !important;
}

.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  color: transparent;
  border-top: 1.1em solid rgba(0, 123, 255, 0.2);
  border-right: 1.1em solid rgba(0, 123, 255, 0.2);
  border-bottom: 1.1em solid rgba(0, 123, 255, 0.2);
  border-left: 1.1em solid #007bff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



